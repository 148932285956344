import React from 'react'
import { observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'

import FPartialTextField from 'ui/formik-form/FPartialTextField'
import FCheckboxField from 'ui/formik-form/FCheckboxField'
import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { setCookies } from 'libs/common/cookies'

import { isProduction } from 'utils/env.utils'
import { validateUsername } from 'utils/validators.utils'
import { getAffiliateCookies } from 'utils/affiliatePrograms.utils'
import { debounceEvent } from 'utils/helpers.utils'
import { pushToDataLayer, isDataLayerAvailable } from 'utils/GTMDataLayer.utils'
import { isEmpty } from '@elo-kit/utils/validators.utils'

import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { ROOT_URL } from 'constants/general.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import { notify } from 'libs/common/notify'

import { createProfile } from 'shared/api/user.api'

import './become-seller-modal.scss'

interface BecomeSellerModalProps {
  isOpen: boolean
  toggle: () => void
  userStore: any
  urlParams?: string
  successLandingUrl?: string
  applyElopagePromo?: (token: string) => Record<string, boolean>
  token?: string
  payerItem?: {
    fullName: string
  }
}

export const BecomeSellerModal: React.FC<BecomeSellerModalProps> = observer(
  ({ isOpen, toggle, userStore, urlParams = '', successLandingUrl, applyElopagePromo, token, payerItem }) => {
    const isBecomeSellerSignIn = userStore.isBecomeSellerSignIn()

    const initialValues = {
      username: payerItem?.fullName?.split(' ').join('-'),
      acceptedTerms: false,
    }

    const submitForm = async (values, { setSubmitting }) => {
      const requestData = {
        termsAccepted: true,
        profileType: ACTIVE_PROFILES.seller,
        username: (values.username || '').toLowerCase(),
        ...getAffiliateCookies(),
      }

      const { success } = await createProfile(requestData)
      if (success) {
        const { profileTypes, email } = userStore.item
        const noSellerProfile = profileTypes.indexOf(ACTIVE_PROFILES.seller) < 0

        if (noSellerProfile && !isEmpty(payerItem) && isDataLayerAvailable() && isProduction()) {
          const requestData = {
            event: 'segment_payer_flow',
            email,
            sign_up_date: moment().format(DATE_FORMATS.NOT_SEPARATED_YYYYMMDD),
          }
          pushToDataLayer(requestData)
        }

        toggle()
        await setCookies('cabinet_profile_type', 'seller')
        notify('success', I18n.t('react.shared.notific.created'))
        if (applyElopagePromo) {
          const resp = await applyElopagePromo(token)

          const promoToken = `ablefy_promo_token=${token}`
          const defaultUrl = `${ROOT_URL}/cabinet?${promoToken}`

          if (resp?.success) {
            window.location.href = successLandingUrl ? `${successLandingUrl}?${promoToken}` : defaultUrl
          } else {
            window.location.href = defaultUrl
          }
        } else {
          window.location.href = `/cabinet${urlParams || window.location.search}`
        }
      } else {
        setSubmitting(false)
      }
    }

    const debouncedValidateUsername = debounceEvent(validateUsername, 500)

    return (
      <EloModal isOpen={isOpen} onClose={toggle} className='become-seller-modal'>
        <EloModalHeader>
          {isBecomeSellerSignIn
            ? I18n.t('react.shared.become_seller_existing_user.title')
            : I18n.t('react.shared.become_seller')}
        </EloModalHeader>
        <Formik initialValues={initialValues} onSubmit={submitForm}>
          {({ isValid, values }) => {
            const submitDisabled = !isValid || !values.acceptedTerms

            return (
              <Form>
                <EloModalBody>
                  <div className='become-seller-modal__trial-period'>
                    {I18n.t('react.shared.sign_up_form.trial_period')}
                  </div>

                  <Field
                    required
                    lowercased
                    name='username'
                    placeholder={I18n.t('react.shared.link')}
                    predefinedText={I18n.t('react.shared.shop_link')}
                    component={FPartialTextField}
                    validate={debouncedValidateUsername}
                    label={I18n.t('react.shared.seller_modal.shop_link')}
                  />

                  <div
                    dangerouslySetInnerHTML={{ __html: I18n.t('react.shared.username_note') }}
                    className='become-seller-modal__password-description'
                  />

                  <Field
                    name='acceptedTerms'
                    component={FCheckboxField}
                    label={I18n.t('react.shared.accept_terms.title', {
                      terms_href: '/terms',
                      privacy_href: '/privacy',
                    })}
                  />
                </EloModalBody>
                <EloModalFooter className='elo-ui-modal__footer--end'>
                  <EloButton variant='highlight' disabled={submitDisabled}>
                    {I18n.t('react.shared.button.create_seller_account')}
                  </EloButton>
                </EloModalFooter>
              </Form>
            )
          }}
        </Formik>
      </EloModal>
    )
  }
)

BecomeSellerModal.displayName = 'BecomeSellerModal'
